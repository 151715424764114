import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { attendIn, attendOut, loadServerStudents, addStudent, flushStudent, updateStudent } from '../redux/actions/actions';

const Clock = () => {
    const dispatch = useDispatch();
    const [currentTime, setCurrentTime] = useState('');
    const [attendanceLocked, setAttendanceLocked] = useState(false);
    // const [attendanceLocalStorage, setAttendanceLocalStorage] = useState('');
    const [attendanceLocalStorage, setAttendanceLocalStorage] = useState(localStorage.getItem('attendanceLocked'));
    const [currentTimeSnap, setCurrentTimeSnap] = useState('');
    const [attDoneForToday, setAttDoneForToday] = useState(Boolean(localStorage.getItem('isAbsentDoneForToday')));
    const [attBtnDisable, setAttBtnDisable] = useState(false);
    const [totalStudents, setTotalStudents] = useState(JSON.parse(localStorage.getItem('serverData')));
    const scannedStudents = useSelector(state => state.scannedStudents);
    const [reset, setReset] = useState('')
    const [presentStudents, setPresentStudents] = useState(0)
    const [absentStudents, setAbsentStudents] = useState(0)
    const localConfigure = JSON.parse(localStorage.getItem('localConfigure'));
    let intervalId;

    function attendanceDate() {//if it is next day
        const currentDate = new Date();
        const dateOnly = currentDate.toLocaleDateString(); // Format: MM/DD/YYYY (or depending on locale)
      // console.log((dateOnly));
        const getPrvDate  = localStorage.getItem('attendanceDate')
        //console.log(getPrvDate != dateOnly)
        if(getPrvDate != String(dateOnly)){
          dispatch(flushStudent())
          dispatch(loadServerStudents(JSON.parse(localStorage.getItem('serverData'))))
          localStorage.setItem('attendanceLocked', 'false');
          localStorage.setItem('attendanceDate', dateOnly);
          localStorage.setItem('isAbsentDoneForToday', 'false')

          console.log('It is a new day-------------------------------------')
        }
        else{
        //  console.log('It is NOT new day');
        }
      }

    function showCurrentTime() {
        const absents =scannedStudents.filter(item => item.timeIn == '' )
        const presents =scannedStudents.filter(item => item.timeIn != '' )
        console.log(scannedStudents[0].time)
        console.log(presents.length)
        setPresentStudents(presents.length)
        setAbsentStudents(absents.length)
        setAttendanceLocked(localStorage.getItem('attendanceLocked'))
        //setAttendanceLocalStorage(localStorage.getItem(attendanceLocked))
        // console.log(attenda0nceLocalStorage)
        const currentDate = new Date();
        let hours = currentDate.getHours();
        let hours24 = currentDate.getHours();
        const amPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const currentTime = `${hours}:${minutes}:${seconds} ${amPm}`;
        const timeTwentyFour = `${hours24}:${minutes}`;
        attendanceDate();
        if(localStorage.getItem('attendanceLocked') == 'false'){
            setCurrentTime(currentTime);
        localStorage.setItem('ClockTime', currentTime);
        const getConfigure = JSON.parse(localStorage.getItem('localConfigure'));

        
        const [hours1, minutes1] = getConfigure.attLockTime.split(':').map(Number);
        // console.log(hours1, hours24, Number(minutes), minutes1 )
            if((Number(hours24) == Number(hours1))){
                if( (Number(minutes) >= Number(minutes1)) ){
                    setAttendanceLocalStorage(true)
                    setAttendanceLocked(true);
                    setVariables();
                    localStorage.setItem('attendanceLocked', 'true');
                }
            }
            if((Number(hours24) > Number(hours1))){
                    setAttendanceLocalStorage(true)
                    setAttendanceLocked(true);
                    setVariables();
                    localStorage.setItem('attendanceLocked', 'true');
            }

        }
        else{
           setAttendanceLocked(true) 
        }


    }
    
    function setVariables(){
        setAttendanceLocalStorage(true)

    }

    useEffect(() => {
        // showCurrentTime();
        intervalId = setInterval(showCurrentTime, 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (attendanceLocked == true) {
            console.log('----------it is locked---------')
            remainingStudentsAttendance();
        }
        console.log(attendanceLocked, 'attendanceLocked')
    }, [attendanceLocked]);

    function getDate(getDate){
        const currentTime = getDate;
        const year = currentTime.getFullYear();
        const month = String(currentTime.getMonth() + 1).padStart(2, '0');
        const day = String(currentTime.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      function removeLocalStorage(){
        console.log(reset)
        if(reset == 'wasily@70610%#30'){
            localStorage.clear();
        }
      }
      function updateReset(e){
        console.log(e.target.value)
        setReset(e.target.value)
      }

    function completeTodayAtt(){
        console.log(attDoneForToday)
       // return false;
       setAttBtnDisable(true)
        remainingStudentsAttendance()
    }
    async function remainingStudentsAttendance() {
        try {
            const currentDate = new Date();

        const fetchAbsentStudents = scannedStudents.filter(student => 
            student.timeIn == '' && student.att == 0
        );

        console.log(fetchAbsentStudents.length)
        setAttBtnDisable(true)
        const isAbsentDoneForToday = localStorage.getItem('isAbsentDoneForToday')
            //const fetchAbsentStudents = getDataFromLocStorage.scannedStudents.filter(item => item.timeIn != '' && item.att == 0 );// for only incomings 
           if( fetchAbsentStudents.length > 0 ){
                if( isAbsentDoneForToday == 'false' ){
                    const userToken = localStorage.getItem('User_Data');
                    const apiEndpoint = `https://${localConfigure.server}/apk/attendanceRemainingStudents`;
                    const payload = { others: {catchDate:  getDate(currentDate), WebSrn : localConfigure.WebSrn, oneOrTwoWay: localConfigure.oneOrTwoWay }, studentsList: fetchAbsentStudents }
                    const response = await axios.post(apiEndpoint, payload, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${userToken}`,
                        },
                    });
                    if (response.status === 200) {
                        fetchStudents()
                        localStorage.setItem('isAbsentDoneForToday', 'true')
                        setAttDoneForToday(true)
                    } else {
                        setAttDoneForToday(false)
                        setAttBtnDisable(false)
                        throw new Error('Failed to fetch students');
                    }
                }
           }
           else{
            fetchStudents()
           }
        } catch (error) {
            setAttDoneForToday(false)
            setAttBtnDisable(false)
            console.error('Error:', error);
            // Handle error gracefully, maybe display a message to the user
        }
    }


    async function fetchStudents() {
        try {
            const userToken = localStorage.getItem('User_Data');
            const apiEndpoint = `https://${localConfigure.server}/apk/fetchStudents`;
            const payload = { WebSrn: localConfigure.WebSrn };
            const response = await axios.post(apiEndpoint, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            });
            if (response.status === 200) {
                localStorage.setItem('serverData', JSON.stringify(response.data.message));
                // localStorage.setItem('serve  rDataTest', JSON.stringify(response.data.message));
            } else {
                throw new Error('Failed to fetch students');
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle error gracefully, maybe display a message to the user
        }
    }

    return (
        <div className='clockComponent'>
            
            <div className='resetApp'>
                    <input type='text' onChange={updateReset}  />
                    <button onClick={removeLocalStorage}>R</button>
                </div>
            {/* {attendanceLocked ?  currentTimeSnap : currentTime} */}
            {currentTime}
            {attendanceLocked == true? 
                <>
                <p>حاضري بنده سوه</p>
                <p>Attendance Stopped...{attendanceLocked}</p>
                { attDoneForToday == true ? <p style={{color: 'green'}}>دنن حاضري بشپړه سوه </p> : attDoneForToday == false? 
                    <button disabled={attBtnDisable} className={attBtnDisable? 'disbaleAttBtn': ''} style={{width: '150px'}} onClick={completeTodayAtt}>حاضري وکړي</button> : <p>Waiting...</p>}
                </>
                :
                null 
            }
            {/* <table className='studentsStatus'>
                <tr>
                    <td colSpan={3}>
                    د نن ورځې معلومات
                    </td>
                </tr>
                <tr>
                    <td>غیرحاضر متعلم</td>
                    <td>حاضر متعلم</td>
                    <td>ټول متعلم تعداد</td>
                </tr>
                <tr>
                    <td>{absentStudents}</td>
                    <td>{presentStudents}</td>
                    <td>{totalStudents.length}</td>
                </tr>
            </table> */}
        </div>
    );
}

export default Clock;
