// redux/reducers.js
import { combineReducers } from 'redux';
import { LOAD_SERVER_STUDENTS, ATTEND_IN, ATTEND_OUT, ADD_STUDENT, UPDATE_STUDENT, REMOVE_ALL_STUDENT } from '../actions/actions';

const scannedStudentsReducer = (state = [], action) => {
 // console.log(state)
   console.log(action.payload)
  switch (action.type) {
    case LOAD_SERVER_STUDENTS: 
    return loaderAllFromServer(action.payload)
    case ADD_STUDENT:
      return [...state, action.payload];
    case REMOVE_ALL_STUDENT:
      return [];
    case UPDATE_STUDENT:
      return updateStateRecord(state, action.payload);
    case ATTEND_IN:
      return attendIn(state, action.payload);
    case ATTEND_OUT:
      return attendOut(state);
    default:
      return state;
  }
}

const loaderAllFromServer = (getData) => {
  const newData = getData.map(item => {
    return {
      ...item,
      att: 0, // Add your desired value for the 'att' property here
      timeIn: '', // Add your desired value for the 'timeIn' property here
      timeOut: '' // Add your desired value for the 'timeIn' property here
    };
  });
  console.log(newData)
  return newData
}

const attendIn2 = (currentStore, updatedValues) => {
  console.log(updatedValues)
  updatedValues =
  [{RollNum: 59, classID: 0, Name_P: "بشیراحمد فاروقي", att: 0, timeIn: "", timeOut: ""},
  {RollNum: 91, classID: 0, Name_P: "t", att: 0, timeIn: "", timeOut: ""},
  {RollNum: 92, classID: 27, Name_P: "احمد", att: 0, timeIn: "", timeOut: ""}]
  currentStore.forEach(item1 => {
   const getAttendStds = updatedValues.forEach(element => {
      console.log(element)
      return element.RollNum == item1.RollNum
    });
      // const matchingItem = updatedValues.find((item2) => {
      //   console.log(item1, item2)
      //   return item1.RollNum === item2.RollNum;
      // });
      console.log(getAttendStds);

      // if (matchingItem) {
      //     matchingItem.att = 1;
      // }
  });

  // If no match was found, return false
  return currentStore;
};

const attendIn = (currentStore, updatedValues) => {
  console.log(updatedValues)
  // updatedValues =
  // [{RollNum: 59, classID: 0, Name_P: "بشیراحمد فاروقي", att: 0, timeIn: "", timeOut: ""},
  // {RollNum: 121, classID: 0, Name_P: "t", att: 0, timeIn: "", timeOut: ""},
  // {RollNum: 118, classID: 27, Name_P: "احمد", att: 0, timeIn: "", timeOut: ""}]
  const getInd = -1
  currentStore.forEach( ( item1, ind) => {
      const matchingItem = updatedValues.find(item2 => item1.RollNum === item2.RollNum);

      if (matchingItem != undefined) {
        console.log(matchingItem);
        
         return  item1.att = 1;
      }
  });
  if (getInd !== -1) {
    currentStore[getInd].att = 1;
  }
    // If no match was found, return false
  return currentStore;
};
const attendOut = (getData) => {}

const updateStateRecord = (state, act) =>{
  console.log(act)
  const updatedState = state.map(item => {
    if (item.rollNum === act.rollNum) {
      return { ...item, out: act.out };
    }
    return item;
  });
  return updatedState;
}

const rootReducer = combineReducers({
  scannedStudents: scannedStudentsReducer
});

export default rootReducer;
