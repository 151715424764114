import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { attendIn, attendOut, loadServerStudents, addStudent, flushStudent, updateStudent } from '../redux/actions/actions';
import useScanDetection from 'use-scan-detection';
import './BarcodeScanner.css'
import Clock from "./Clock";

const BarcodeScanner = () => {
  const dispatch = useDispatch();
  const [notValid, setNotValid] = useState(false);
  const [student, setStudent] = useState('');
  const scannedStudents = useSelector(state => state.scannedStudents);
  const [currentTime, setCurrentTime] = useState('');
  const [localConfigure, setLocalConfigure] = useState(JSON.parse(localStorage.getItem('localConfigure')));
  const [showMessage, setShowMessage] = useState('');
  const [arrivalDepart, setArrivalDepart] = useState('arrived');
  const [fetchUnAttendStudents, setFetchUnAttendStudents] = useState([]);
  const [customDisplayMsg, setCustomDisplayMsg]= useState('')
  const [presentStudents, setPresentStudents] = useState(0)
  const [absentStudents, setAbsentStudents] = useState(0)
  const [totalStudents, setTotalStudents] = useState(JSON.parse(localStorage.getItem('serverData')));

  function attendanceDate() {
    const currentDate = new Date();
    const dateOnly = currentDate.toLocaleDateString(); // Format: MM/DD/YYYY (or depending on locale)
  // console.log((dateOnly));
    const getPrvDate  = localStorage.getItem('attendanceDate')
    if(getPrvDate != String(dateOnly)){
      localStorage.setItem('attendanceDate', dateOnly);
      //dispatch(flushStudent())
      console.log(scannedStudents)
    }
    else{
      console.log('----');
    }
  }
  


  function parseTime(timeStr) {
    // Split the time string into hours, minutes, seconds, and AM/PM parts
    const [time, amPm] = timeStr.split(' ');
    const [hours, minutes, seconds] = time.split(':').map(num => parseInt(num, 10));
    
    // Adjust hours for PM
    let adjustedHours = hours;
    if (amPm === 'PM' && hours < 12) {
      adjustedHours += 12;
    }
  
    // Create a new Date object with the parsed time
    const date = new Date();
    date.setHours(adjustedHours, minutes, seconds, 0);
    
    return date;
  }

  function attendStudent(getRoll) {
//    console.log(typeof localStorage.getItem('attendanceLocked'))
    attendanceDate()
    console.log(localStorage.getItem('ClockTime'))
    setCurrentTime(localStorage.getItem('ClockTime'))
    const [verify, rollNum, name] = getRoll.split(',');
    if(verify !== 'smartschool') { 
      setNotValid(true)
      // setCustomDisplayMsg('بارکوډ سم نه دی')
      return false
    }
    else{
      setNotValid(false)
    }
    
    if(localStorage.getItem('attendanceLocked')=='true'){
      // setCustomDisplayMsg('اوبخښه ....')
      // setTimeout(function() {
      //   setCustomDisplayMsg('')
      // }, 5000);
      return false
    }
    const filteredIndex = scannedStudents.findIndex((student) => {
       return  student.RollNum === parseInt(rollNum);
    });
    
    const absents = scannedStudents.filter(item => item.timeIn == '' )
    const presents =scannedStudents.filter(item => item.timeIn != '' )
    

    // const eachStudent  scannedStudents[filteredIndex]
    console.log(localStorage.getItem('attendanceLocked')=='false')
    
    const twoWayOrOneWay = localConfigure.oneOrTwoWay 
    if( filteredIndex >= 0 ){
      const isAtt = scannedStudents[filteredIndex].att
      const arrived = scannedStudents[filteredIndex].timeIn != '' ? false : true
      const depart = scannedStudents[filteredIndex].timeOut != '' ? false : true
      console.log(twoWayOrOneWay == 1)
      if( twoWayOrOneWay == 1){
        if(arrived)
          {
            scannedStudents[filteredIndex].timeIn = localStorage.getItem('ClockTime')
            // scannedStudents[filteredIndex].att = 1
            dispatch(updateStudent(scannedStudents[filteredIndex]))
            setStudent(`${scannedStudents[filteredIndex].Name_P}-${rollNum}`);
            setShowMessage('ښه راغلاست')
            setPresentStudents(presents.length)
            setAbsentStudents(absents.length)
          }
          else{
            setStudent(`${scannedStudents[filteredIndex].Name_P}-${rollNum}`);
            setShowMessage(`ستاسو د نن ورځې حاضري اخستل سوې ده   ${scannedStudents[filteredIndex].timeIn}`)
          }
      }
      else if( twoWayOrOneWay == 2){
        if(arrived)
          {
            scannedStudents[filteredIndex].timeIn = localStorage.getItem('ClockTime')
            // scannedStudents[filteredIndex].att = 1
            dispatch(updateStudent(scannedStudents[filteredIndex]))
            setStudent(`${scannedStudents[filteredIndex].Name_P}-${rollNum}`);
            setShowMessage('ښه راغلاست')
            setPresentStudents(presents.length)
            setAbsentStudents(absents.length)
          }
        else if(depart)
          {
            scannedStudents[filteredIndex].timeOut = localStorage.getItem('ClockTime')
            // scannedStudents[filteredIndex].att = 2
            dispatch(updateStudent(scannedStudents[filteredIndex]))
            setStudent(`${scannedStudents[filteredIndex].Name_P}-${rollNum}`);
            setShowMessage('په مخه مو ښه ')
          }
          else{
            setStudent(`${scannedStudents[filteredIndex].Name_P}-${rollNum}`);
            setShowMessage(`${scannedStudents[filteredIndex].timeIn} ----- ${scannedStudents[filteredIndex].timeOut}`)
          }
      }
    }
    else{
      setNotValid(true)
    }
    
  
  }

  useScanDetection({
    onComplete: (code) => {
      attendStudent(code)
    }
  });

  function getDate(getDate){
    const currentTime = getDate;
    const year = currentTime.getFullYear();
    const month = String(currentTime.getMonth() + 1).padStart(2, '0');
    const day = String(currentTime.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const unAttendStudents = () => {
    let attendStudents = []
    let getDataFromLocStorage = JSON.parse(localStorage.getItem('StudentAttendance'))
    setArrivalDepart('arrived')
    attendStudents = getDataFromLocStorage.scannedStudents.filter(item => item.timeIn != '' && item.att == 0 );// for only incomings 
    if(attendStudents <= 0 && localConfigure.oneOrTwoWay == 2){
      attendStudents = getDataFromLocStorage.scannedStudents.filter(item => item.timeOut != '' && item.att == 1 ); //for out going students
      setArrivalDepart('depart')
    }
    //setFetchUnAttendStudents(attendStudents)
    return attendStudents
  }
  function postTimeToAPI() {
    // console.log('---1---')
    const currentDate = new Date();
    // console.log(getDate(currentDate))
    // let filteredStudents = scannedStudents.slice(); // Create a copy of scannedStudents
    // let updateStudents = filteredStudents.
    // console.log(filteredStudents)
    // let attendStudents = ''
    // attendStudents = scannedStudents.filter(item => item.timeIn != '' && item.att == 0 );// for only incomings 
    // if(attendStudents <= 0 && localConfigure.oneOrTwoWay == 2){
    //   attendStudents = scannedStudents.filter(item => item.timeOut != '' && item.att == 1 ); //for out going students
    // }
    
    // Construct the complete URL for the API endpoint
    const apiEndpoint = `https://${localConfigure.server}/apk/attendanceAppAttend`;
    let fetchUnAttendStudents = unAttendStudents()
    if(fetchUnAttendStudents.length > 0 ){
      // Post the time value to the API
    fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ others: {catchDate:  getDate(currentDate), studentStatus:arrivalDepart, WebSrn : localConfigure.WebSrn, oneOrTwoWay: localConfigure.oneOrTwoWay }, studentsList: fetchUnAttendStudents }) // Assuming you're sending the time value as JSON
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to post time to API');
      }
      console.log(response)
      dispatch(attendIn(fetchUnAttendStudents))
    })
    .catch(error => {
      console.error('Error:', error);
    });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
        postTimeToAPI();

      }, 30 * 1000); // Run every 5 minutes (5 * 60 * 1000 milliseconds)

    return () => clearInterval(interval); // Clear the interval when the component unmounts
}, []);

// runEveryFiveMinutes();
  useEffect(()=>{ 
    console.log('useEffect running')
    // setLocalConfigure(localStorage.getItem('localConfigure'))
  }, [fetchUnAttendStudents])

  // setInterval(postTimeToAPI, 1 * 60 * 1000); // Run every 5 minutes (5 * 60 * 1000 milliseconds)
  return (
    <div>
      { notValid ? <h1 className='alert'>{customDisplayMsg}</h1> :
        <>
        <h2>{student}</h2>
        <h2>{showMessage}</h2>
        </>
      }
      <h1>{<Clock/>}</h1>
      <table className='studentsStatus'>
                <tr>
                    <td colSpan={3}>
                    نن ورځ
                    </td>
                </tr>
                <tr>
                    <td>غیرحاضر متعلم</td>
                    <td>حاضر متعلم</td>
                    <td> متعلم تعداد</td>
                </tr>
                <tr className='records'>
                    <td>{absentStudents}</td>
                    <td>{presentStudents}</td>
                    <td>{totalStudents.length}</td>
                </tr>
            </table>
    </div>
  );
}

export default BarcodeScanner;
