// redux/store.js

import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers/reducers';
import { localStorageMiddleware } from '../middleware/localStorage';

// Load the initial state from localStorage
const persistedState = localStorage.getItem('StudentAttendance') 
  ? JSON.parse(localStorage.getItem('StudentAttendance'))
  : {};

// Create the Redux store with middleware
const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(localStorageMiddleware)
);

export default store;
