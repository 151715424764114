// App.js
import { Provider } from 'react-redux';
import store from './redux/store/store';
import WelcomeScreen from './components/WelcomeScreen';
 import BarcodeScanner from './components/BarcodeScanner';
// import BarcodeScanner from './components/barcodeReader/BrowserBarcodeReader';
import React, { useEffect, useState } from 'react';
import useOnlineStatus from './components/useOnlineStatus';
import './App.css'
import  GetLocalStorage  from "./components/GetLocalStorage";

function App() {
  const isOnline = useOnlineStatus();
  const existingAttendanceLocked = localStorage.getItem('attendanceLocked');
  if (existingAttendanceLocked !== null) {
    localStorage.setItem('attendanceLocked', 'false');  
  }

  const [isVerified, setIsVerified] = useState(localStorage.getItem('verified') === 'true');

  const handleVerification = () => {
    localStorage.setItem('verified', 'true');
    setIsVerified(true);
  };
  // if(localStorage.getItem('verified') == undefined || localStorage.getItem('verified') == 'undefined' || localStorage.getItem('verified') == null)
  //   localStorage.setItem('verified', 'false');
  // console.log(localStorage.getItem('verified'))

  // function BlinkingButton({ text, isOnline }) {
  //   const buttonClass = `button blink ${isOnline ? 'online' : 'offline'}`;
  
  //   return <span className={buttonClass}>{text}</span>;
  // }

  return (
    <Provider store={store}>
      <div className="App">
          <div>
            <span className={`buttonClass blink ${isOnline ? 'online' : 'offline'}`}>
          {isOnline ? 'Online' : 'Offline'}
      </span>
          </div>
      <div>
          { 
            isVerified ? 
            <>
              {/*   <GetLocalStorage/> */}
              <BarcodeScanner/>
            </>
            :
            <WelcomeScreen onVerification={handleVerification} />
          }
        </div>
      </div>
    </Provider>
  );
}

export default App;
