import React, { useState } from 'react';
import './WelcomeScreen.css'; // Import CSS file for styling
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { loadServerStudents } from '../redux/actions/actions';


function WelcomeScreen({ onVerification }) {
  const dispatch = useDispatch();

  const [res, setRes] = useState('')
  const [successMsg, setSuccessMsg] = useState(false)
  const [formData, setFormData] = useState({
    mobile: '',
    server: 'mobile.smartschool.host',
    oneOrTwoWay: '1',
    schoolStartTime: '',
    attLockTime: '',
    schoolCloseTime: '',
    WebSrn: '',
  });

  const [disableBtn, setDisableBtn] = useState(false)
  const handleChange = (e) => {
    if(e.target.name == 'server')
      localStorage.setItem('server', e.target.value);

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    console.log(formData)
  };

  async function fetchSuggestions() {
    setDisableBtn(true)
    try {
        
        // Construct the complete URL for the API endpoint
        const apiEndpoint = 'https://'+formData.server + '/apk/attendanceAppVerify';

        // Send POST request to the API endpoint with formData
        const response = await axios.post(apiEndpoint, formData);

        // Check if the response status is 200
        if (response.status === 200) {
            // Log the response data for debugging
            console.log(response.data);
            localStorage.setItem('serverData', JSON.stringify(response.data.message))
            localStorage.setItem('User_Data', response.data.token)
            dispatch(loadServerStudents(response.data.message))
            setTimeout(() => {
                setDisableBtn(false)
                setRes('You are Successfully Connected to the System and ready to Start Attending Students')
                localStorage.setItem('verified', 'true')
                verificationDone()
              }, 1000)
        } else {
            setDisableBtn(false)
            // Log an error if the response status is not 200
            console.error('Unexpected response status:', response.status);
        }
    } catch (error) {
        setDisableBtn(false)
        // Log any caught errors for debugging
        console.error('Error occurred while fetching suggestions:', error);
    }
}

  const verificationDone = () => {
    onVerification()
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('server', formData.server);
    localStorage.setItem('localConfigure', JSON.stringify(formData));
    // localStorage.setItem('schoolStartTime', formData.schoolStartTime);
    // localStorage.setItem('attLockTime', formData.attLockTime);
    // localStorage.setItem('schoolCloseTime', formData.schoolCloseTime);
    // console.log('Form data saved to local storage:', formData);
    fetchSuggestions();
  };

  return (
    <div className="form-container">
      <h2>XaoSoft Technologies</h2>
      { successMsg == false ? 
      <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="mobile">Contact Person Mobile:</label>
        <input type="text" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="server">Server:</label>
        <input type="text" id="server" name="server" value={formData.server} onChange={handleChange} placeholder='mobile.smartschool.host' required />
      </div>

      <div className="form-group">
        <label htmlFor="WebSrn">WebSrn:</label>
        <input type="text" id="WebSrn" name="WebSrn" value={formData.WebSrn} onChange={handleChange} placeholder='' required />
      </div>

      <div className="form-group">
        <label htmlFor="oneOrTwoWay">Attendance One Or Two Way</label>
        <select id="oneOrTwoWay" name="oneOrTwoWay" value={formData.oneOrTwoWay} onChange={handleChange} required>
          <option value="1">One Way</option>
          <option value="2  ">Two Way</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="schoolStartTime">School Start Time</label>
        <input type="time" id="schoolStartTime" name="schoolStartTime" value={formData.schoolStartTime} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="attLockTime">Attendance Lock Time</label>
        <input type="time" id="attLockTime" name="attLockTime" value={formData.attLockTime} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <label htmlFor="schoolCloseTime">School Close Time</label>
        <input type="time" id="schoolCloseTime" name="schoolCloseTime" value={formData.schoolCloseTime} onChange={handleChange} required />
      </div>

      <div className="form-group">
        <button disabled={disableBtn} type="submit">Submit</button>
      </div>
    </form>
    :
    <h2>{res}</h2>
    }
    </div>
  );
}

export default WelcomeScreen;
