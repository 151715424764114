// redux/actions.js
export const ADD_STUDENT = 'ADD_STUDENT';
export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const REMOVE_ALL_STUDENT = 'REMOVE_ALL_STUDENT';
export const LOAD_SERVER_STUDENTS = 'LOAD_SERVER_STUDENTS';
export const ATTEND_IN = 'ATTEND_IN';
export const ATTEND_OUT = 'ATTEND_OUT';

export const loadServerStudents = (studentsList) => ({
  type: LOAD_SERVER_STUDENTS,
  payload: studentsList
});

export const addStudent = student => ({
  type: ADD_STUDENT,
  payload: student
});

export const updateStudent = student => ({
  type: UPDATE_STUDENT,
  payload: student
});

export const attendIn = (studentsList) => ({
  type: ATTEND_IN,
  payload: studentsList
});

export const attendOut = () => ({
  type: ATTEND_OUT,
});

export const flushStudent = () => ({
  type: REMOVE_ALL_STUDENT,
});

export const removeStudent = student => ({
  type: REMOVE_STUDENT,
  payload: student
});
